import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full'
	}, {
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
	}, {
		path: 'sem-acesso',
		loadChildren: () => import('./pages/login/sem-acesso/sem-acesso.module').then(m => m.SemAcessoPageModule)
	}, {
		path: 'home',
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
	}, {
		path: 'contracheque',
		loadChildren: () => import('./pages/contracheque/contracheque.module').then(m => m.ContrachequePageModule)
	}, {
		path: 'rendimentos',
		loadChildren: () => import('./pages/rendimentos/rendimentos.module').then(m => m.RendimentosPageModule)
	}, {
		path: 'sobre',
		loadChildren: () => import('./pages/sobre/sobre.module').then(m => m.SobrePageModule)
	}, {
		path: 'termos',
		loadChildren: () => import('./pages/termos/termos.module').then(m => m.TermosPageModule)
	}, {
		path: 'mensagens',
		loadChildren: () => import('./pages/notificacoes/notificacoes.module').then(m => m.NotificacoesPageModule)
	}, {
		path: 'dados-pessoais',
		loadChildren: () => import('./pages/recadastramento/dados-pessoais/dados-pessoais.module').then(m => m.DadosPessoaisPageModule)
	}, {
		path: 'tabs-recadastramento',
		loadChildren: () => import('./pages/recadastramento/recadastramento-tabs/recadastramento-tabs.module').then(m => m.TabsRecadastramentoPageModule)
	}, {
		path: 'recadastramento',
		loadChildren: () => import('./pages/recadastramento/recadastramento/recadastramento.module').then(m => m.RecadastramentoPageModule)
	}, {
		path: 'camera',
		loadChildren: () => import('./pages/camera/camera.module').then(m => m.CameraPageModule)
	}, {
		path: 'resumo',
		loadChildren: () => import('./pages/recadastramento/resumo/resumo.module').then(m => m.ResumoPageModule)
	}, {
		path: 'resumo-recadastramento-concluido',
		loadChildren: () => import('./pages/recadastramento/resumo-recad-concluido/resumo-recad-concluido.module').then(m => m.ResumoRecadConcluidoPageModule)
	}, {
		path: 'dependentes',
		loadChildren: () => import('./pages/dependentes/dependentes.module').then(m => m.DependentesPageModule)
	}, {
		path: 'validacao-facial',
		loadChildren: () => import('./pages/validacao-facial/validacao-facial.module').then(m => m.ValidacaoFacialPageModule)
	}, {
		path: 'representante-legal',
		loadChildren: () => import('./pages/representante-legal/representante-legal.module').then(m => m.RepresentanteLegalPageModule)
	}, {
		path: 'atendente/:page',
		loadChildren: () => import('./pages/atendente/atendente.module').then(m => m.AtendentePageModule)
	}, {
		path: 'pendencias',
		loadChildren: () => import('./pages/pendencias/pendencias.module').then(m => m.PendenciasPageModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
