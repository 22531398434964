export class PushMessage {
	title?: string;
	message?: string;
	userData: any;
	foreground: boolean;

	constructor(payload: any = { }, foreground: boolean, isNativo: boolean) {
		this.foreground = foreground;
		
		if (isNativo) {
			if (payload.title) this.title = payload.title;
			if (payload.body) this.message = payload.body;
			this.userData = payload.data || payload.notification.data;
		} else if (payload.notification) {
			this.title = payload.notification.title;
			this.message = payload.notification.body;
			try {
				let data = payload.data || payload.notification.data;
				this.userData = JSON.parse(data);
			} catch (error) {
				this.userData = { };
				console.error(error);
			}
		}
	}
}
