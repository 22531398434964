import { Component } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { SwUpdate } from '@angular/service-worker';
import { Preferences } from '@capacitor/preferences';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Market } from '@ionic-native/market/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AlertController, LoadingController, MenuController, NavController, Platform, ToastController } from '@ionic/angular';
import firebase from 'firebase/app';
import "firebase/remote-config";
import { environment } from 'src/environments/environment';
import { EventService } from 'src/services/event.service';
import { MensagemService } from 'src/services/mensagem.service';
import { UsuarioService } from 'src/services/usuario.service';
import { APP_CONFIG } from './commom/constants';
import { AppEvents } from './enums/app-events.enum';
import { TipoRecadastramento } from './enums/tipo-recadastramento.enum';
import { VersaoFirebase } from './enums/versao-firebase';
import { VersionCompare } from './enums/version-compare.enum';
import { UtilHelper } from './helpers/utils.helper';
import { DevicePush } from './models/device-push.model';
import { MenuItem } from './models/menu-item.model';
import { PushMessage } from './models/push-message.model';

declare var window: any;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {

	anos: any = [];
	appPages: Array<MenuItem> = [];
	pushRegistrado: boolean;
	installPrompt: any;

	private usuario: any;
	private jaMostrouAvisoAtualizacao: boolean;

	constructor(
		private afMessaging: AngularFireMessaging,
		private appVersion: AppVersion,
		private device: Device,
		private events: EventService,
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private menuCtrl: MenuController,
		private mensagemService: MensagemService,
		private navCtrl: NavController,
		private loadingCtrl: LoadingController,
		private alertCtrl: AlertController,
		private market: Market,
		private swUpdate: SwUpdate,
		private toastController: ToastController,
		private usuarioService: UsuarioService
	) {
		this.iniciarlizarApp();
	}

	iniciarlizarApp(): void {
		this.platform.ready().then(async () => {
			this.statusBar.styleLightContent();

			this.registerEvents();
			this.verificarConfiguracoesRemotas()
			this.usuario = await this.usuarioService.carregarUsuario();
			if (this.usuario) {
				this.obterMenus();
			}

			if (this.platform.is("capacitor")) {
				setTimeout(() => {
					this.splashScreen.hide();
				}, 500);
			} else {
				// TODO: testar melhor
				// window.addEventListener('beforeinstallprompt', (e) => {
				// 	console.log('beforeinstallprompt Event fired');
				// 	// Prevent Chrome 67 and earlier from automatically showing the prompt
				// 	e.preventDefault();
				// 	// Stash the event so it can be triggered later.
				// 	this.installPrompt = e;
				// });
			}
		});
	}

	private registerEvents(): void {
		this.events.subscribe(AppEvents.ATUALIZAR_MENUS, () => {
			this.obterMenus();
		});
		this.events.subscribe(AppEvents.HOME_ENTER, async (usuario: any) => {
			this.showInstallBanner();
			if (!this.pushRegistrado) {
				this.initPushNotification();
			}
		});
		this.events.subscribe(AppEvents.LOGIN_APP, async (usuario) => {
			if (usuario) {
				this.usuario = usuario;
				await this.deletePushToken();

				this.initPushNotification();
				this.mensagemService.obterTotalNaoLidas();
			}
		});
		this.events.subscribe(AppEvents.LOGOUT, () => {
			this.handleLogout(false);
		});
		this.events.subscribe(AppEvents.CALL_LOGOUT, async () => {
			this.logout();
		});
	}

	closeMenu(): void {
		this.menuCtrl.close();
	}

	private async obterMenus(): Promise<void> {
		if (!this.usuario) {
			return;
		}
		try {
			// Filtra para mostrar apenas os menus que todos podem acessar, ou somente o perfil que tem acesso.
			this.appPages = await this.usuarioService.obterMenus(this.abrirPagina.bind(this), true);
		} catch (error) {
			console.error(error);
		}
	}

	abrirPagina(item: MenuItem): void {
		if (!item) return;
		if (item.subPages) {
			item.isOpen = !item.isOpen
			return;
		}
		this.closeMenu();
		if (this.usuarioService.perfilUsuario.atendeBalcao == "S") {
			switch (item.page) {
				case "dados-pessoais":
				case "tabs-recadastramento":
				case "dependentes":
				case "representante-legal":
				case "pendencias":
					this.navCtrl.navigateForward("atendente/" + item.page, { queryParams: item.params });
					return;
			}
		}
		if (item.page == "tabs-recadastramento") {
			if (this.usuarioService.dados && this.usuarioService.dados.codTipoRecadastramento == TipoRecadastramento.MANUTENCAO_CONTINUA) {
				this.showAlert("Recadastramento do ano vigente já foi concluído. Caso deseje realizar alguma alteração cadastral, acesse o menu <b>Dados Pessoais</b>.");
				return;
			}
		}
		this.navCtrl.navigateForward(item.page, { queryParams: item.params });
	}

	async logout(): Promise<void> {
		const alert: HTMLIonAlertElement = await this.alertCtrl.create({
			cssClass: 'alertInfo',
			header: 'Logout',
			message: 'Tem certeza que deseja sair? Você deixará de receber avisos personalizados da ParanáPrevidência.',
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel',
					cssClass: 'invertido',
					handler: () => {
						this.closeMenu();
					}
				}, {
					text: 'OK',
					handler: async () => {
						this.handleLogout(true);
					}
				}
			]
		});
		await alert.present();
	}

	private async handleLogout(redirectLogin: boolean): Promise<void> {
		try {
			await this.deletePushToken();
		} catch (error) {
			console.error(error);
		} finally {
			this.usuario = null;
			await this.usuarioService.removerUsuario();
			this.closeMenu();
			
			// Inicializa para obter um novo token de push, mas sem registrar o usuário. Assim será possível ainda receber pushs não direcionados.
			this.initPushNotification();

			if (redirectLogin) {
				this.navCtrl.navigateRoot('/login');	
			}
		}
	}

	/* PUSH
	 * ============================================================ */

	async initPushNotification(): Promise<void> {
		try {
			if (this.platform.is("capacitor")) {
				const resultPermission = await PushNotifications.requestPermissions();
				if (resultPermission.receive === 'granted') {
					PushNotifications.register();
				}
				PushNotifications.addListener('registration', (token: Token) => {
					console.log('Push registration success, token: ' + token.value);
					this.registerFCMToken(token.value);
				});
				PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
					console.log('Push received: ' + JSON.stringify(notification));
					try {
						this.tratarMensagemPush(new PushMessage(notification, true, true));
					} catch (error) {
						console.error(error);
					}
				});
				PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
					console.log('Push action performed: ' + JSON.stringify(notification));
					try {
						this.tratarMensagemPush(new PushMessage(notification, false, true));
					} catch (error) {
						console.error(error);
					}
				});
			} else {
				// WEB PUSH NOTIFICATION
				this.afMessaging.requestToken.subscribe((token) => {
					this.registerFCMToken(token);
				});
				this.afMessaging.messages.subscribe((payload) => {
					console.log("New WEB FCM message: ", payload);
					try {
						this.tratarMensagemPush(new PushMessage(payload, true, false));
					} catch (error) {
						console.error(error);
					}
				});
			}
		} catch (error) {
			console.error(error);
		}
	}

	private async registerFCMToken(fcmToken: string): Promise<void> {
		try {
			if (this.usuario && fcmToken) {
				let devicePush: DevicePush = await this.usuarioService.obterDevicePushToken();
				if (!devicePush || devicePush.token != fcmToken) {
					console.log("new token: ", fcmToken);
					if (devicePush) {
						await this.usuarioService.removerDevicePushToken(devicePush);
					}
					const versaoLocal: string = this.platform.is("capacitor") ? await this.appVersion.getVersionNumber() : APP_CONFIG.WEB_VERSION;
					devicePush = new DevicePush(this.device, versaoLocal, fcmToken);
					await this.usuarioService.salvarDevicePushToken(devicePush);
				}
				this.pushRegistrado = true;
			}
		} catch (error) {
			console.error(error); 
		}
	}

	private async deletePushToken(): Promise<void> {
		try {
			const devicePush: DevicePush = await this.usuarioService.obterDevicePushToken();
			if (devicePush) {
				if (this.platform.is("capacitor")) {
					await PushNotifications.unregister();
				} else {
					await this.afMessaging.deleteToken(devicePush.token).toPromise(); // Web
				}
				await this.usuarioService.removerDevicePushToken(devicePush);
			}
		} catch (error) {
			console.log(error);
		}
	}

	private async tratarMensagemPush(push: PushMessage): Promise<void> {
		if (this.usuario != null) {
			this.mensagemService.obterTotalNaoLidas();

			if (push.foreground) {
				const toast: HTMLIonToastElement = await this.toastController.create({
					message: push.message,
					// duration: 5000,
					position: "top",
					color: 'dark',
					buttons: [{
						text: "Ver",
						side: "end",
						cssClass: "white",
						handler: () => {
							this.navCtrl.navigateForward("mensagens");
						}
					}]
				});
				toast.present();
			} else {
				this.navCtrl.navigateForward("mensagens");	
			}
		}
	}

	showInstallBanner(): void {
		try {
			if (this.installPrompt) {
				// Show the prompt
				this.installPrompt.prompt();
				// Wait for the user to respond to the prompt
				this.installPrompt.userChoice
					.then((choiceResult) => {
						if (choiceResult.outcome === 'accepted') {
							console.log('User accepted the A2HS prompt');
						} else {
							console.log('User dismissed the A2HS prompt');
						}
						// We no longer need the prompt.  Clear it up.
						this.installPrompt = null;
					});
			}
		} catch (error) {
			console.error(error);
		}
	}

	private async verificarConfiguracoesRemotas(): Promise<void> {
		try {
			firebase.initializeApp(environment.firebaseConfig);
	
			let remoteConfig = firebase.remoteConfig();
			remoteConfig.settings.minimumFetchIntervalMillis = 600000; // 10 min
	
			await remoteConfig.fetchAndActivate();

			if (this.platform.is("capacitor")) {
				const keyFirebase: string = this.platform.is("android") ? "versao_app_android" : "versao_app_ios";
				const respVersao = remoteConfig.getValue(keyFirebase);
				if (respVersao) {
					const versaoRemota: VersaoFirebase = JSON.parse(respVersao.asString());
					if (versaoRemota) {
						this.comparaVersaoApp(versaoRemota);
					}
				}
			} else {
				this.checarVersaoPwa();
			}
			const resp = remoteConfig.getValue("versao_termos");
			if (resp) {
				await Preferences.set({ key: "termos", value: resp.asString() });
				let versaoTermos = JSON.parse(resp.asString());
				if (versaoTermos) {
					APP_CONFIG.TERMOS_USO = versaoTermos;
				}
			}
		} catch (erro) {
			console.log(erro)
		}
	}

	async checarVersaoPwa(): Promise<void> {
		console.log("swUpdate isEnabled: " + this.swUpdate.isEnabled);
		if (this.swUpdate.isEnabled) {
			this.swUpdate.available.subscribe(async (event) => {
				console.log('current version is', event.current);
				console.log('available version is', event.available);
				if (!this.jaMostrouAvisoAtualizacao) {
					this.jaMostrouAvisoAtualizacao = true;
					const alert: HTMLIonAlertElement = await this.alertCtrl.create({
						cssClass: 'alertInfo',
						header: "Atualização disponível",
						message: "Para um melhor funcionamento, recarregue a página para atualizar.",
						backdropDismiss: false,
						buttons: [{
							text: 'Mais tarde',
							role: 'cancel',
							cssClass: 'invertido'
						}, {
							text: "Atualizar",
							handler: (data) => {
								this.updateApp();
							}
						}]
					});
					await alert.present();
				}
			});
			this.swUpdate.checkForUpdate();
		}
	}

	private async comparaVersaoApp(versaoRemota: VersaoFirebase): Promise<void> {
		try {
			const versaoLocal = await this.appVersion.getVersionNumber();
			const resultadoComparacao: VersionCompare = UtilHelper.comparaVersao(versaoLocal, versaoRemota.versao);
			switch (resultadoComparacao) {
				case VersionCompare.VERSAO_REMOTA_MAIOR:
					if (versaoRemota.obrigatorio) {
						const buttons: Array<any> = [];
						if (this.platform.is("android")) {
							buttons.push({
								text: 'Mais tarde',
								role: 'cancel',
								handler: (data) => {
									navigator['app'].exitApp();
								}
							});
						}
						buttons.push({
							text: "Atualizar",
							handler: (data) => {
								this.abrirAplicativo();
								return false;
							}
						});
						const alert: HTMLIonAlertElement = await this.alertCtrl.create({
							header: "Atualização",
							message: "Existe uma atualização disponível. Atualize o app para continuar utilizando.",
							buttons: buttons,
							backdropDismiss: false
						});
						await alert.present();
					} else {
						const toast: HTMLIonToastElement = await this.toastController.create({
							message: "Existe uma atualização disponível.",
							duration: 8000,
							color: 'dark',
							buttons: [{
								side: 'end',
								text: 'Atualizar',
								handler: () => {
									this.abrirAplicativo();
								}
							}]
						});
						await toast.present();
					}
					break;

				case VersionCompare.VERSAO_LOCAL_MAIOR:
					console.log("ATUALIZE A VERSÃO REMOTA NO FIREBASE, a versão atual remota " + versaoRemota + " é INFERIOR a versão local " + versaoLocal);
					break;

				case VersionCompare.VERSOES_IGUAIS:
					console.log("versão atual remota " + versaoRemota + " é igual a versão local " + versaoLocal);
					break;

				default:
					break;
			}
		} catch (error) {
			console.error(error);
		}
	}

	private async abrirAplicativo(): Promise<void> {
		const loading: HTMLIonLoadingElement = await this.loadingCtrl.create();
		try {
			await loading.present();
			const appId: string = this.platform.is("ios") ? APP_CONFIG.APPS.ios : APP_CONFIG.APPS.android;
			await this.market.open(appId);
		} catch (error) {
			this.showToast("Houve um erro ao tentar abrir a loja de aplicativos");
		} finally {
			loading.dismiss();
		}
	}

	private async updateApp(): Promise<void> {
		const loading: HTMLIonLoadingElement = await this.loadingCtrl.create();
		try {
			await loading.present();
			this.swUpdate.activateUpdate()
				.then(() => document.location.reload())
				.catch(() => document.location.reload());
		} catch (error) {
			this.showToast("Houve um erro ao tentar atualizar");
		} finally {
			loading.dismiss();
		}
	}

	private async showToast(mensagem: string): Promise<void> {
		const toast: HTMLIonToastElement = await this.toastController.create({
			message: mensagem,
			duration: 3500
		});
		toast.present();
	}

	private async showAlert(mensagem: string, title?: string, okTitleButton?: string, ): Promise<void> {
		const alert: HTMLIonAlertElement = await this.alertCtrl.create({
			cssClass: 'alertInfo',
			header: title || "",
			message: mensagem,
			buttons: [
				{
					text: okTitleButton || 'OK'
				}
			]
		});
		await alert.present();
	}
}
