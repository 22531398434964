import { AcaoDependente } from "../enums/acao-dependente.enum";
import { Certidao } from "./certidao.model";
import { Item } from "./item.model";

export class Dependente {
	codigoCatalogo: string;
	idMeta4: string;
	nome: string;
	sexo: string;
	estadoCivil: string;
	estadoCivilTitular: string;
	paisNascimento: string;
	estadoNascimento: string;
	cidadeNascimento: string; // codigo IBGE
	descricaoCidadeNascimento: string;
	dataNascimento: string;
	nomePai: string;
	nomeMae: string;
	deveSer: AcaoDependente;
	motivoExclusao: Item;
	dataSeparacaoFatica: string;
	dataEmancipacao: string;
	numeroRg: string;
	ufRg: string;
	dataExpedicaoRg: string;
	orgaoEmissorRg: string;
	cpf: string; // cpf do titular
	cpfDependente: string;
	vinculoDependencia: string;
	descricaoVinculoDependencia: string;
	email: string;
	ir: "S" | "N";
	possuiRenda: "S" | "N";
	listaCertidaoDTO: Array<Certidao>;
	invalido: "S" | "N";
	universitario: "S" | "N";

	// auxilia na tela
	estadoCivilSelecionado: Item;
	atualizadoPeloRepresentanteLegal: "S" | "N";
	confirmaRepresentanteLegal: "S" | "N";
	isCpfFromSIV: boolean;
	isRgInfoFromService: boolean;
	novoCodigoCatalogo: boolean;

	constructor(obj: any = { }) {
		Object.assign(this, obj);
	}

	toJsonIncluir(): any {
		const dados: any = {
			codigoCatalogo: this.codigoCatalogo,
			idMeta4: this.idMeta4,
			nome: this.nome,
			sexo: this.sexo,
			estadoCivil: this.estadoCivil,
			estadoCivilTitular: this.estadoCivilTitular,
			paisNascimento: this.paisNascimento,
			estadoNascimento: this.estadoNascimento,
			cidadeNascimento: this.cidadeNascimento,
			descricaoCidadeNascimento: this.descricaoCidadeNascimento,
			dataNascimento: this.dataNascimento,
			nomePai: this.nomePai,
			nomeMae: this.nomeMae,
			deveSer: this.deveSer,
			dataSeparacaoFatica: this.dataSeparacaoFatica,
			dataEmancipacao: this.dataEmancipacao,
			numeroRg: this.numeroRg,
			ufRg: this.ufRg,
			dataExpedicaoRg: this.dataExpedicaoRg,
			orgaoEmissorRg: this.orgaoEmissorRg,
			cpf: this.cpf,
			cpfDependente: this.cpfDependente,
			vinculoDependencia: this.vinculoDependencia,
			descricaoVinculoDependencia: this.descricaoVinculoDependencia,
			email: this.email,
			ir: this.ir,
			possuiRenda: this.possuiRenda,
			listaCertidaoDTO: this.listaCertidaoDTO,
			invalido: this.invalido,
			universitario: this.universitario
		};
		return dados;
	}

	toJsonAlterar(): any {
		const dados: any = {
			codigoCatalogo: this.codigoCatalogo,
			idMeta4: this.idMeta4,
			cpf: this.cpf,
			cpfDependente: this.cpfDependente,
			deveSer: this.deveSer,
			estadoCivil: this.estadoCivil,
			ir: this.ir,
			possuiRenda: this.possuiRenda,
			invalido: this.invalido,
			universitario: this.universitario
		};
		return dados;
	}

	toJsonExcluir(): any {
		const dados: any = {
			codigoCatalogo: this.codigoCatalogo,
			idMeta4: this.idMeta4,
			cpf: this.cpf,
			deveSer: this.deveSer,
			listaCertidaoDTO: this.listaCertidaoDTO
		};
		if (this.motivoExclusao) {
			dados.motivoExclusao = this.motivoExclusao.codigo;
			if (this.motivoExclusao.codigo == "SF" && this.dataSeparacaoFatica) {
				dados.dataSeparacaoFatica = this.dataSeparacaoFatica;
			}
		}
		return dados;
	}
}
