import { Injectable } from "@angular/core";
import { Mensagem } from "src/app/models/mensagem.model";
import { RemoteService } from "./remote.service";
import { WEBSERVICE } from 'src/app/commom/constants';

@Injectable({
	providedIn: 'root'
})
export class MensagemService {

	totalNaoLidas: number = 0;

	constructor(
		private remoteService: RemoteService
	) { }

	async obterTotalNaoLidas(): Promise<void> {
		try {
			const response = await this.remoteService.getData(WEBSERVICE.OBTER_TOTAL_MENSAGENS_NAO_LIDAS);
			this.totalNaoLidas = response?.totalNaoLida || 0;
		} catch (error) { }
	}

	async obterMensagens(): Promise<Array<Mensagem>> {
		return await this.remoteService.getData(WEBSERVICE.OBTER_MENSAGENS) || [];
	}

	async marcarMensagemComoLida(idMensagem: number): Promise<void> {
		await this.remoteService.postData(`${WEBSERVICE.MARCAR_MENSAGEM_LIDA}/${idMensagem}`);
		this.obterTotalNaoLidas();
	}

}
