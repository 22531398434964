import { Injectable } from '@angular/core';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { APP_CONFIG, WEBSERVICE } from 'src/app/commom/constants';
import { Item } from 'src/app/models/item.model';

import { RemoteService } from './remote.service';

@Injectable({
	providedIn: 'root'
})
export class ParanaPrevService {

	periodoContracheque: { maximo: string, minimo: string };
	anosRendimentos: Array<string>;

	constructor(
		private file: File,
		private platform: Platform,
		private remoteService: RemoteService
	) { }

	async consultaRg(rg: string): Promise<any> {
		return await this.remoteService.getData(WEBSERVICE.BUSCA_RG + rg) as any;
	}

	async consultaEndereco(cep: string): Promise<any> {
		return await this.remoteService.getData(WEBSERVICE.BUSCA_ENDERECO + cep + '/2') as any;
	}

	async consultaLocalidade(codLocalidade: string): Promise<any> {
		return await this.remoteService.getData(WEBSERVICE.BUSCA_LOCALIDADE + codLocalidade + '/2/2') as any;
	}

	async consultaMunicipioPorUf(uf: string): Promise<Array<Item>> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ uf: uf }, WEBSERVICE.BUSCA_MUNICIPIOS_UF);
		const response: any = await this.remoteService.getData(urlRequisicao) as any;
		const municipios: Array<Item> = [];
		if (response && response.length > 0) {
			response.forEach((m) => {
				municipios.push(new Item(m.chave, m.nome));
			});
		}
		return municipios;
	}

	async consultarPeriodoContracheque(): Promise<any> {
		const result: any = await this.remoteService.getData(WEBSERVICE.OBTER_DATAS_CONTRACHEQUE) as any;
		if (result && result.maximo) {
			this.periodoContracheque = result;
		}
		return result;
	}

	async consultarRemuneracaoMesAno(mes: string, ano: string): Promise<any> {
		const params: any = {
			mes: mes,
			ano: ano
		};
		const endpoint: string = this.remoteService.buildUrlQueryParams(params, WEBSERVICE.CONTRACHEQUE_POR_DATA);
		const result: any = await this.remoteService.getData(endpoint) as any;
		return result;
	}

	async consultarUltimaRemuneracao(): Promise<any> {
		return await this.remoteService.getData(WEBSERVICE.ULTIMO_CONTRACHEQUE) as any;
	}

	async consultarContrachequePdf(data: string): Promise<FileEntry> {
		const endpoint: string = this.remoteService.buildUrlQueryParams({ data: data }, WEBSERVICE.CONTRACHEQUE_PDF);
		const filename: string = "contracheque-" + data.replace(/:/g, "-") + ".pdf";
		const destFilename: string = this.platform.is("capacitor") ? (this.file.dataDirectory + filename) : filename;
		const response: FileEntry = await this.remoteService.downloadFile(endpoint, destFilename);
		console.log(response);
		return response;
	}

	async consultaAnos(): Promise<any> {
		const result: any = await this.remoteService.getData(WEBSERVICE.ANOS_RENDIMENTOS) as any;
		this.anosRendimentos = result;
		return result;
	}

	async consultaRendimentos(ano: string, cpf: string): Promise<any> {
		const result: any = await this.remoteService.getData(WEBSERVICE.COMP_RENDIMENTOS + cpf + '/' + ano) as any;
		if (result) {
			// Faz o encode corretamente.. algumas vezes está vindo no final da string \u003d causando erro ao converter para arquivo pdf
			return decodeURIComponent(JSON.parse('"' + result + '"'));
		}
		return result;
	}

	async consultaPermissaoRecadastro(cpf: string): Promise<"S" | "N"> {
		return await this.remoteService.getData(WEBSERVICE.VERIFICA_RECADASTRAMENTO + cpf);
	}

	async consultaDadosUsuario(cpf: string): Promise<any> {
		return await this.remoteService.getData(WEBSERVICE.DADOS_CADASTRO + cpf);
	}

	async enviarDadosCadastrais(dados: any): Promise<any> {
		if (!this.platform.is("capacitor")) {
			this.remoteService.addParamsHeader("responseType", "text");
		}
		const result: any = await this.remoteService.putData(WEBSERVICE.ATUALIZACAO_DADOS, dados);
		return result;
	}

	// async obterTermo(): Promise<any> {
	// 	const response: FileEntry = await this.remoteService.downloadFile(APP_CONFIG.TERMOS_USO.url, this.file.dataDirectory + "Termo.pdf");
	// 	console.log(response);
	// 	return response && response.nativeURL || null;
	// }
}
