import { Component } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { UsuarioService } from 'src/services/usuario.service';

@Component({
	selector: "app-modal-pendencias",
	templateUrl: "./modal-pendencias.page.html",
	styleUrls: ["./modal-pendencias.page.scss"]
})
export class ModalPendenciasPage {

	descricao: string;

	constructor(
		private modalCtrl: ModalController,
		private navCtrl: NavController,
		private navParams: NavParams,
		private usuarioService: UsuarioService
	) {
		this.obterDescricao();
	}

	async obterDescricao(): Promise<void> {
		this.descricao = this.navParams.get("descricao") || "Existem pendências no seu cadastro. Para resolver agora, selecione <b>\"Resolver agora\"</b>.<br>Para resolver em outro momento, acesso o menu <b>\"Pendências\"</b>.";
	}

	async resolverAgora(): Promise<void> {
		if (this.usuarioService.perfilUsuario.atendeBalcao == "S") {
			this.navCtrl.navigateForward("atendente/pendencias");
		} else {
			this.navCtrl.navigateForward("pendencias");
		}
		this.modalCtrl.dismiss();
	}

	dismiss(): void {
		this.modalCtrl.dismiss();
	}
}
