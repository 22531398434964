import { TipoServidor } from "../enums/tipo-servidor.enum";
import { Endereco } from "./endereco.model";
import { Item } from "./item.model";

export class RepresentanteLegal {
	codigoCatalogo: string;
	idMeta4: string;
	rg: string;
	rgUF: string;
	dtEmissao: string;
	orgaoEmissao: string;
	nomeRepresentanteLegal: string;
	tipoRepresentanteLegal: string;
	descricaoTipoRepresentanteLegal: string;
	paisNasc: string;
	estadoNasc: string;
	municipioNasc: string; // código IBGE
	descricaoMunicipioNasc: string;
	dtNasc: string;
	pai: string;
	mae: string;
	email: string;
	cpf: string;
	cpfTitular: string;
	enderecos: Array<Endereco>;
	definitivo: string;
	nomeNovoRepresentante: string;
	cpfNovoRepresentante: string;

	// Criado objeto de endereço separado para facilitar no formulário de cadastro
	endereco: Endereco;
	mesmoEnderecoTitular: "S" | "N";
	tipoSelecionado: Item;
	novoOuAlteradoPeloRecadastramento: boolean; // auxilia na view para saber se está cadastrando um novo representante ou alterando pelo fluxo do recadastramento
	isCpfFromSIV: boolean;
	isRgInfoFromService: boolean;
	novoCodigoCatalogo: boolean;

	// Mantis 66795: Dados necessários para enviar na hora de inserir
	tipoServidor: TipoServidor;
	tipoPensionista: string;

	constructor(obj: any = { }) {
		Object.assign(this, obj);

		if (!this.enderecos) {
			this.enderecos = [];
		} else {
			this.endereco = this.enderecos.find((endereco: Endereco) => endereco.tipoEndereco == "RES");
		}
	}

	toJson(alterouRepresentante: boolean): any {
		const dados: any = { };
		dados.codigoCatalogo = this.codigoCatalogo;
		dados.idMeta4 = this.idMeta4;
		dados.rg = this.rg;
		dados.rgUF = this.rgUF;
		dados.dtEmissao = this.dtEmissao;
		dados.orgaoEmissao = this.orgaoEmissao;
		dados.nomeRepresentanteLegal = this.nomeRepresentanteLegal;
		dados.paisNasc = this.paisNasc;
		dados.estadoNasc = this.estadoNasc;
		dados.municipioNasc = this.municipioNasc; // código IBGE
		dados.descricaoMunicipioNasc = this.descricaoMunicipioNasc;
		dados.dtNasc = this.dtNasc;
		dados.pai = this.pai;
		dados.mae = this.mae;
		dados.email = this.email;
		dados.cpf = this.cpf;
		dados.cpfTitular = this.cpfTitular;
		dados.definitivo = this.definitivo;
		dados.enderecos = [this.endereco];
		dados.tipoRepresentanteLegal = this.tipoSelecionado.codigo;

		if (alterouRepresentante) {
			dados.nomeNovoRepresentante = this.nomeRepresentanteLegal;
			dados.cpfNovoRepresentante = this.cpf;
		}
		if (this.tipoServidor) {
			dados.tipoServidor = this.tipoServidor;
		}
		if (this.tipoPensionista) {
			dados.tipoPensionista = this.tipoPensionista;
		}

		return dados;
	}
}
