import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
	selector: "app-modal-helper",
	templateUrl: "./modal-helper.page.html",
	styleUrls: ["./modal-helper.page.scss"]
})
export class ModalHelperPage {
	titulo = '';
	texto = '';
	textoBotaoAcao = '';
	textoBotaoDismiss = '';
	marginTop = '';
	icon: { name?: string, src?: string, color: string };

	constructor(
		private modalCtrl: ModalController,
		private navParams: NavParams
	) {
		this.titulo = this.navParams.get('titulo');
		this.texto = this.navParams.get('texto');
		this.textoBotaoAcao = this.navParams.get('textoBotaoAcao');
		this.textoBotaoDismiss = this.navParams.get('textoBotaoDismiss');
		this.marginTop = this.navParams.get('marginTop');
		this.icon = this.navParams.get('icon');
		console.log(this.marginTop);
	}

	dismiss(): void {
		this.modalCtrl.dismiss();
	}

	acaoBotao(): void {
		this.modalCtrl.dismiss({ acao: true });
	}
}
