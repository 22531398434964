import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Mensagem } from 'src/app/models/mensagem.model';
import { MensagemService } from 'src/services/mensagem.service';

@Component({
	selector: "app-modal-notificacao",
	templateUrl: "./modal-notificacao.page.html",
	styleUrls: ["./modal-notificacao.page.scss"]
})
export class ModalNotificacaoPage {

	mensagens: Array<Mensagem>;

	constructor(
		private modalCtrl: ModalController,
		private navCtrl: NavController,
		public mensagemService: MensagemService,
	) { }

	async verNotificacoes(): Promise<void> {
		this.dismiss();
		this.navCtrl.navigateForward("mensagens");
	}

	dismiss(): void {
		this.modalCtrl.dismiss();
	}
}
