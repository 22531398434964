import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Item } from 'src/app/models/item.model';

@Component({
	selector: 'app-modal-pesquisa',
	templateUrl: './modal-pesquisa.page.html',
	styleUrls: ['./modal-pesquisa.page.scss'],
})
export class ModalPesquisaPage implements OnInit {

	/**
	 * Essa modal pode ser usada para qualquer pesquisa genérica, desde que seja passada
	 * uma lista de ItemSelecao para ela.
	 */

	titulo: string;
	listaOriginal: Array<Item>;
	listaFiltrada: Array<Item>;
	textoFiltro: string = "";

	constructor(
		private modalCtrl: ModalController,
		private navParams: NavParams
	) { }

	ngOnInit(): void {
		this.titulo = this.navParams.get("titulo") || "";
		this.listaOriginal = this.navParams.get("lista") || [];
		this.listaFiltrada = this.listaOriginal || [];
	}

	dismiss(): void {
		this.modalCtrl.dismiss();
	}

	selecionar(item: Item): void {
		this.modalCtrl.dismiss(item);
	}

	filtrar(): void {
		if (this.textoFiltro && this.textoFiltro.trim().length) {
			const texto: string = this.textoFiltro.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
			console.log("pesquisar: " + texto);

			this.listaFiltrada = this.listaOriginal.filter((item: Item) => {
				const nome: string = item.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
				return nome.indexOf(texto.trim()) > -1;
			});
		} else {
			this.limparFiltro();
		}
	}

	limparFiltro(): void {
		this.textoFiltro = null;
		this.listaFiltrada = this.listaOriginal;
	}
}
