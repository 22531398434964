import { Device } from "@ionic-native/device/ngx";
import { isPlatform } from "@ionic/angular";

export class DevicePush {
	aparelho: string; //"Motorola - Moto G7 - Android 8.0",
	versaoApp: string;
	token: string;
	app: string;

	constructor(device: Device, versaoApp: string, pushToken: string) {
		if (isPlatform("capacitor")) {
			this.aparelho = device.manufacturer + " - " + device.model + " - " + device.platform + " " + device.version;
		} else {
			this.aparelho = navigator.userAgent;
		}
		this.versaoApp = versaoApp;
		this.token = pushToken;
		this.app = "PRPREV"
	}
}
