import { Component } from '@angular/core';
import { Filesystem, ReadFileResult } from '@capacitor/filesystem';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { Arquivo } from 'src/app/models/arquivo.model';

@Component({
	selector: 'app-modal-foto-preview',
	templateUrl: './modal-foto-preview.page.html',
	styleUrls: ['./modal-foto-preview.page.scss'],
})
export class ModalFotoPreviewPage {

	picture: string = "";
	mostrarBotoesAcao: boolean;

	constructor(
		private modalCtrl: ModalController,
		private navParams: NavParams,
		private platform: Platform
	) {
		this.prepararImagem();
	}

	private async prepararImagem(): Promise<void> {
		try {
			this.mostrarBotoesAcao = this.navParams.get('mostrarBotoesAcao');
			const arquivo: any = this.navParams.get('picture');
			console.log(arquivo);
			if (arquivo && arquivo instanceof Arquivo) {
				if (this.platform.is("capacitor")) {
					const file: ReadFileResult = await Filesystem.readFile({ path: arquivo.urlNativo });
					this.picture = "data:image/png;base64," + file.data;
				} else {
					const reader: FileReader = new FileReader();
					reader.addEventListener('load', (event) => {
						this.picture = event.target.result as string;
					});
					reader.readAsDataURL(arquivo.pwaFile);
				}
			} else {
				this.picture = arquivo;
			}
		} catch (error) {
			console.error(error);
		}
	}

	dismiss(): void {
		this.modalCtrl.dismiss();
		this.liberarMemoria();
	}

	aprovar(): void {
		this.modalCtrl.dismiss({ ok: true });
	}

	private liberarMemoria(): void {
		try {
			URL.revokeObjectURL(this.picture);
		} catch (error) {
			console.error(error);
		}
	}
}
