export class ErroApp {
	status: number;
	name?: string;
	message: string;
	tryAgain: boolean;

	constructor(status: number, message?: string) {
		this.status = status;
		this.message = message;
	}
}
