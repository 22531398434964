// Adaptado de https://github.com/aaronpk/pkce-vanilla-js/
export class PKCEHelper {

	static buildUrlParams(params: any, urlRequest: string): string {
		if (params == null)
			return null;

		for (const key in params) {
			if (params[key] != null) {
				urlRequest = urlRequest.replace("{" + key + "}", encodeURIComponent(params[key]));
			}
		}
		console.log("PKCEHelper urlRequest: " + urlRequest);
		return urlRequest;
	}

	static buildUrlQueryParams(params: any, urlRequest: string): string {
		if (!params) return urlRequest;

		let paramStr: string = '';
		for (const key in params) {
			if (params[key] || (typeof params[key] === 'number' && params[key] === 0)) {
				paramStr += paramStr.length === 0 ? '?' : '&';
				paramStr += (key + '=' + encodeURIComponent(params[key])).trim();
			}
		}
		return (urlRequest || '').replace('?', '') + paramStr;
	}

	static parseAuthCodeCentral(url: string): any {
		try {
			const responseParameters: Array<string> = ((url).split("?")[1]).split("&");
			const parsedResponse: any = { };
			for (const param of responseParameters) {
				parsedResponse[param.split("=")[0]] = (param.split("=")[1]).replace("#", "");
			}
			if (parsedResponse["code"]) {
				return parsedResponse;
			}
			return null;
		} catch (error) {
			console.log(error);
			return null;
		}
	}

	// Generate a secure random string using the browser crypto functions
	static generateRandomString(): string {
		const array = new Uint8Array(32);
    	window.crypto.getRandomValues(array);
		return PKCEHelper.base64urlEncode(array);
	}

	static base64urlEncode(array: Uint8Array): string {
		return btoa(String.fromCharCode.apply(null, array))
		  .replace(/\+/g, '-')
		  .replace(/\//g, '_')
		  .replace(/=+$/, '');
	}

	// Calculate the SHA256 hash of the input text. Returns a promise that resolves to an ArrayBuffer
	static sha256(plain: string): PromiseLike<ArrayBuffer> {
		const encoder: TextEncoder = new TextEncoder();
		const data: Uint8Array = encoder.encode(plain);
		return window.crypto.subtle.digest('SHA-256', data);
	}

	// Return the base64 sha256 hash for the PKCE challenge
	static async pkceChallengeFromVerifier(value: string): Promise<string> {
		const hashed: ArrayBuffer = await PKCEHelper.sha256(value);
		const codeChallenge = PKCEHelper.base64urlEncode(new Uint8Array(hashed));
    	return codeChallenge;
	}
}
